a:link {
  color: #222222;
  text-decoration: none;
}
/* A link that has been visited */
a:visited {
  color: #222222;
}
/* A link that is hovered on */
a:hover {
  color: #444444;
}
/* A link that is selected */
a:active {
  color: #222222;
}
