.current-location {
    background-image: url('http://maps.google.com/mapfiles/ms/icons/blue-dot.png');
    position: absolute;
    cursor: pointer;
    width: 32px;
    height: 32px;
    top: -32px;
    left: -16px;
    transform-origin: 16px 32px;
    margin: 0;
    padding: 0;
}