.place-marker {
    position: absolute;
    cursor: pointer;
    width: 90.709px;
    height: 90.709px;
    top: -90.709px;
    left: -45.3545px;
    transform-origin: 45.3545px 90.709px;
    margin: 0;
    padding: 0;
    transform: translate3D(0, 0, 0) scale(0.25, 0.25);
}

.place-marker.rating1 {
    background-image: url('../../assets/marker_1.svg');
}

.place-marker.rating2 {
    background-image: url('../../assets/marker_2.svg');
}

.place-marker.rating3 {
    background-image: url('../../assets/marker_3.svg');
}

.place-marker.rating4 {
    background-image: url('../../assets/marker_4.svg');
}

.place-marker.rating5 {
    background-image: url('../../assets/marker_5.svg');
}